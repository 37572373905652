import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Form,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { updateUser } from '../../services/apiFetches';

function EditAdd(props) {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const toggle = () => setModal(!modal);
  const { data, classNames } = props;
  const { id } = data;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // @function { onSubmit } - Función encargada de manejar los eventos del formulario.
  // @params { object } info - Los datos recibidos por el formulario.
  // @params { number } id - Es el id del usuario a editar, viene por props desde userList.
  // @function { toggle } - Desactiva el modal.

  const onSubmit = async (info) => {
    try {
      console.log(info);
      if (info.password === '' || info.confirmPassword === '') {
        delete info.password;
        delete info.confirmPassword;
      } else if (info.password !== info.confirmPassword) return;
      info.permissions = info.permissions.filter((item) => item);
      const responseOk = await updateUser({ newUserInfo: info, userID: id });
      if (!responseOk) {
        setError(true);
      } else {
        props.fetchUsers();
        toggle();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const keyPermissions = {
    Oldelval: 'tim-icons icon-chart-pie-36 ',
    Siemens: 'tim-icons icon-bag-16 ',
    BTU: 'tim-icons icon-bank ',
    admin: 'tim-icons icon-single-02 ',
    YPF: 'tim-icons icon-components ',
  };
  useEffect(() => {
    setValue(
      'permissions.0',
      data.permissions.includes('Siemens') ? 'Siemens' : false,
    );
    setValue('permissions.1', data.permissions.includes('BTU') ? 'BTU' : false);
    setValue(
      'permissions.2',
      data.permissions.includes('Oldelval') ? 'Oldelval' : false,
    );
    setValue('permissions.3', data.permissions.includes('YPF') ? 'YPF' : false);
    setValue(
      'permissions.4',
      data.permissions.includes('admin') ? 'admin' : false,
    );
  }, [data, setValue]);

  return (
    <section>
      <i onClick={toggle} className="tim-icons icon-pencil text-success mr-5" />

      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader className="h3" toggle={toggle}>
          EditarUsuario
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Nombre:</label>
                  <input
                    defaultValue={data.name}
                    type="text"
                    className="form-control"
                    // value={data.name}
                    {...register('name', {
                      maxLength: {
                        value: 20,
                        message: 'El maximo de caracteres es de 20',
                      },
                    })}
                  />
                  {errors.name && (
                    <span className="alert_red">{errors.name.message}</span>
                  )}
                </FormGroup>
              </Col>

              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Apellido:</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.lastName}
                    {...register('lastName', {
                      maxLength: {
                        value: 20,
                        message: 'EL maximo de caracteres es de 20',
                      },
                    })}
                  />
                  {errors.lastname && (
                    <span className="alert_red">{errors.lastname.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Correo Electronico:</label>
                  <input
                    placeholder="mike@email.com"
                    type="email"
                    className="form-control"
                    defaultValue={data.email}
                    {...register('email', {
                      pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
                    })}
                  />
                </FormGroup>
              </Col>

              <Col className="pr-md-1 pl-md-1" md="4">
                <FormGroup>
                  <label>Contraseña:</label>
                  <input
                    type="password"
                    className="form-control"
                    {...register('password')}
                  />
                </FormGroup>
              </Col>

              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Confirmación de contraseña:</label>
                  <input
                    type="password"
                    className="form-control"
                    {...register('confirmPassword')}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Usuario:</label>
                  <input
                    className="form-control"
                    defaultValue={data.userName}
                    {...register('userName')}
                  />
                  {errors.userName && (
                    <span className="alert_red">
                      {errors.userName.message}{' '}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Telefono:</label>
                  <input
                    placeholder="(+54) 9 11 0000 0000"
                    type="tel"
                    className="form-control"
                    defaultValue={data.phoneNumber}
                    {...register('phoneNumber', {
                      pattern: /^[0-9]+$/,
                      minLength: {
                        value: 8,
                        message: 'error message',
                      },
                    })}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-md-1" md="4">
                <fieldset>
                  <label>Permisos:</label>
                  <FormGroup className="d-flex justify-content-between mb-0">
                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['Siemens']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Siemens"
                      />
                      <input
                        value={'Siemens'}
                        type="checkbox"
                        className="form-control"
                        {...register('permissions.0', { value: 'Siemens' })}
                      />
                    </FormGroup>

                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['BTU']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="BTU"
                      />
                      <input
                        value={'BTU'}
                        type="checkbox"
                        className="form-control"
                        {...register('permissions.1', { value: 'BTU' })}
                      />
                    </FormGroup>

                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['Oldelval']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Oldelval"
                      />
                      <input
                        type="checkbox"
                        value={'Oldelval'}
                        className="form-control"
                        {...register('permissions.2', { value: 'Oldelval' })}
                      />
                    </FormGroup>
                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['YPF']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="YPF"
                      />
                      <input
                        value={'YPF'}
                        type="checkbox"
                        className="form-control"
                        {...register('permissions.3', { value: 'YPF' })}
                      />
                    </FormGroup>
                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['admin']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="admin"
                      />
                      <input
                        type="checkbox"
                        value={'admin'}
                        className="form-control"
                        {...register('permissions.4', { value: 'admin' })}
                      />
                    </FormGroup>
                  </FormGroup>
                </fieldset>
              </Col>
            </Row>

            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={toggle}
                className="mr-5 mt-3 mb-1"
              >
                Cancelar
              </Button>

              <Button color="blue" type="submit" className="ml-5 mt-3 mb-1">
                Editar
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </section>
  );
}

export default EditAdd;
