import React, { useState, useEffect } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from 'reactstrap';
import UserAdd from './UserAdd';
import UserEdit from './UserEdit';
import { getUsers } from '../../services/apiFetches';
import { Loader } from '../../components/loader/loader';
import DeleteUser from './UserDelete';
const UserList = () => {
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const fetchUsers = async () => {
    const userList = await getUsers();
    if (userList === undefined) return;
    setIsLoading(false);
    setUsers(userList);
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const keyPermissions = {
    Oldelval: 'tim-icons icon-chart-pie-36 p-right1',
    Siemens: 'tim-icons icon-bag-16 p-right1',
    BTU: 'tim-icons icon-bank p-right1',
    admin: 'tim-icons icon-single-02',
    YPF: 'tim-icons icon-components p-right1',
  };
  if (isLoading) return <Loader />;
  return (
    <div className="wrapper">
      <div className="main-panel mt-1">
        <div className="content">
          <Row>
            <Col xs="12" md="12">
              <Card>
                <div className="content ml-2 mt-2">
                  <UserAdd fetchUsers={fetchUsers} />
                </div>

                <CardHeader>
                  <CardTitle tag="h3">Lista de Usuarios</CardTitle>
                </CardHeader>

                <CardBody>
                  <Table className="tablesorter" responsive>
                    <thead className="text-blue">
                      <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Correo</th>
                        <th className="text-left">Permisos</th>
                        <th className="text-left">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((data) => {
                        return (
                          <tr key={data.id}>
                            <td>{data.name}</td>
                            <td>{data.lastName}</td>
                            <td className="w-25">{data.email}</td>
                            <td className="d-flex justify-content-start mh-100">
                              <Row>
                                {data.permissions.map((key) => (
                                  <p
                                    key={key}
                                    className={keyPermissions[key]}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={key}
                                  ></p>
                                ))}
                              </Row>
                            </td>
                            <td className=" mh-100">
                              <Row>
                                <UserEdit
                                  data={data}
                                  fetchUsers={fetchUsers}
                                  classNames={keyPermissions}
                                />
                                <DeleteUser
                                  data={data}
                                  fetchUsers={fetchUsers}
                                />
                              </Row>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default UserList;
