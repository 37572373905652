import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

class Alerts extends React.Component {
  render() {
    const { data, show } = this.props;
    return (
      <>
        <UncontrolledAlert
          color="danger"
          isOpen={show}
          className="alert-with-icon"
        >
          <span className="tim-icons icon-bell-55" data-notify="icon" />
          <span data-notify="message">{data}</span>
        </UncontrolledAlert>
      </>
    );
  }
}
export default Alerts;

