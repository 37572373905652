import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { deleteUser } from 'services/apiFetches';

function DeleteUser(props) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { data } = props;
  const { id } = data;

  const onSubmitDelete = async () => {
    const responseOk = await deleteUser(id);
    if (!responseOk) {
      return;
    }
    props.fetchUsers();
    toggle();
  };

  return (
    <section>
      <i
        onClick={toggle}
        className="tim-icons icon-simple-remove text-danger"
      />

      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader className="h3" toggle={toggle}>
          Eliminar usuario
        </ModalHeader>

        <ModalBody>
          ¿Estás seguro que deseas eliminar este usuario?
          <ModalFooter>
            <Button type="button" onClick={toggle} className="mr-5 mt-3 mb-1">
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={onSubmitDelete}
              color="danger"
              className="ml-5 mt-3 mb-1"
            >
              Eliminar
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </section>
  );
}

export default DeleteUser;
