import React from 'react';
import BackgroundColorWrapper from '../components/BackgroundColorWrapper/BackgroundColorWrapper';
import ThemeContextWrapper from '../components/ThemeWrapper/ThemeWrapper';
import LoginView from './login';
import { Loader } from '../components/loader/loader';
import { useAuth } from 'hooks/useAuth';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../routes/protectedRoutes';
import PowerBi from './PowerBi';
import LoginRequired from '../routes/LoginRequired';
import UserList from '../components/Users/userList';
import Sidebar from '../components/Sidebar/Sidebar';
import UserProfile from './userProfile';
import AdminNavbar from '../components/Navbars/Navbar';

const App = () => {
  const { isFetch } = useAuth();
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf('nav-open') !== -1,
  );

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    setsidebarOpened(!sidebarOpened);
  };

  if (!isFetch) return <Loader />;
  return (
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <div className="wrapper">
          <BrowserRouter>
            <Sidebar />
            <div className="main-panel">
              <AdminNavbar
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />
              <Routes>
                <Route path="/login" element={<LoginView />} />
                <Route
                  path="/"
                  element={
                    <LoginRequired
                      children={
                        <div className="content mt-5" style={{display:"flex"}}>
                          <PowerBi />
                        </div>
                      }
                    />
                  }
                />
                <Route
                  path="/admin/users"
                  element={
                    <div className="mt-5">
                      <ProtectedRoute access="admin">
                        <UserList />
                      </ProtectedRoute>
                    </div>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <div className="mt-5">
                      <LoginRequired>
                        <UserProfile></UserProfile>
                      </LoginRequired>
                    </div>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace={true} />} />
              </Routes>
            </div>
          </BrowserRouter>
        </div>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  );
};
export default App;
