import React from 'react';

export const Loader = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export const LoaderUser = () => {
  return (
    <div className="loader-userName">
      <div className="loader"></div>
    </div>
  );
};
