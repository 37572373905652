import { Navigate } from 'react-router';
import { useLoginStore } from '../stores/loginStore';

function ProtectedRoute({ children, access }) {
  const { loggedUser } = useLoginStore();
  if (loggedUser === undefined) {
    return (
      <>
        <Navigate to="/login" />
      </>
    );
  }
  if (
    loggedUser.permissions.includes(access) ||
    loggedUser.permissions.includes('admin')
  ) {
    return { ...children };
  }
  return <Navigate to="/" />;
}

export default ProtectedRoute;
