import { useLoginStore } from '../stores/loginStore';
import React from 'react';
import logo from "../assets/Logotipo_verde.png"
function PowerBi() {
  const { activeLink, loggedUserLinks } = useLoginStore();
  let src = loggedUserLinks[activeLink];
  if (activeLink === undefined) return  <div style={{display: "flex", justifyContent: "center", minHeight: "100%", flexDirection: "column"}}> <img src={logo} alt ="logo de inicio" style={{width : "70%", height: "auto", margin: "auto" }}/> </div>
  return (
    <iframe
      title="DEMO2 - DIC23"
      width="100%"
      height={window.innerHeight - 100}
      src={src}
      allowFullScreen={true}
    ></iframe>
  );
}

export default PowerBi;




