const baseUrl = window.origin + '/api';
//const baseUrl = 'http://localhost:5000/api';
export const routes = ['Siemens', 'BTU', 'Oldelval', 'YPF', 'admin'];
/**
 * Logs in a user.
 * @param {Object} options - The login options.
 * @param {string} options.userNameOrEmail - The username or email of the user.
 * @param {string} options.password - The password of the user.
 * @param {boolean} options.remember - Whether to remember the login or not.
 * @returns {Promise<routes|undefined>} A promise that resolves with user permissions if successful, otherwise returns undefined.
 */
export async function login({ userNameOrEmail, password, remember }) {
  try {
    // this is kinda bad, but Im not getting paid enough to care to fix this whole mess
    const response = await fetch(baseUrl + '/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userNameOrEmail, password, remember }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    return undefined;
  }
}

export async function logout() {
  try {
    const response = await fetch(baseUrl + '/logout', {
      method: 'POST',
      credentials: 'include',
    });
    if (response.ok) {
      localStorage.clear();
      return true;
    }
    return false;
  } catch (error) {
    return undefined;
  }
}

export async function getUsers() {
  try {
    const response = await fetch(baseUrl + '/users', {
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function getCurrentUser() {
  try {
    const response = await fetch(baseUrl + '/user', { credentials: 'include' });
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function deleteUser(userID) {
  try {
    const response = await fetch(`${baseUrl}/user/${userID}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    return response.ok;
  } catch (error) {
    return false;
  }
}
export async function updateUser({ newUserInfo, userID }) {
  try {
    const response = await fetch(`${baseUrl}/user/${userID}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUserInfo),
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return false;
  }
}
export async function updateSelf({ newUserInfo }) {
  try {
    const response = await fetch(`${baseUrl}/user/edit`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUserInfo),
    });
    return response.ok;
  } catch (error) {
    console.error(error);
    return false;
  }
}
export async function createUser({ userInfo }) {
  try {
    const response = await fetch(`${baseUrl}/user`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInfo),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getPowerBiSrc({ permissions }) {
  try {
    const links = {
      Siemens: undefined,
      Oldelval: undefined,
      BTU: undefined,
      YPF: undefined,
    };
    if (permissions.includes('admin')) {
      for (const route of routes) {
        if (route === 'admin') continue;
        const response = await fetch(`${baseUrl}/${route}`, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          links[route] = data.powerBiSrc;
        }
      }
      return links;
    }
    for (const route of permissions) {
      const response = await fetch(`${baseUrl}/${route}`, {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        links[route] = data.powerBiSrc;
      }
    }
    return links;
  } catch (error) {
    console.error(error);
  }
}
