import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
} from 'reactstrap';
import { useLoginStore } from '../stores/loginStore';
import { useForm } from 'react-hook-form';
import { updateSelf } from 'services/apiFetches';
function UserProfile() {
  const { loggedUser } = useLoginStore();
  const { register, handleSubmit } = useForm();
  const onSubmit = async (info) => {
    try {
      if (info.password === '' || info.confirmPassword === '') {
        delete info.password;
        delete info.confirmPassword;
      } else if (info.password !== info.confirmPassword) return;
      const responseOk = await updateSelf({
        newUserInfo: info,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="main-panel mt-1">
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Edita tu perfil</h5>
              </CardHeader>

              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Nombre de usuario:</label>
                        <input
                          className="form-control"
                          placeholder="Username"
                          type="text"
                          {...register('userName', {
                            defaultValue: loggedUser.userName,
                          })}
                          defaultValue={loggedUser.userName}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Correo Electronico:
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          {...register('email')}
                          defaultValue={loggedUser.email}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Nombre:</label>
                        <input
                          className="form-control"
                          placeholder="Nombre"
                          type="text"
                          {...register('name')}
                          defaultValue={loggedUser.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Apellido:</label>
                        <input
                          className="form-control"
                          placeholder="Apellido"
                          type="text"
                          defaultValue={loggedUser.lastName}
                          {...register('lastName')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Contraseña</label>
                        <input
                          className="form-control"
                          placeholder="Contraseña"
                          type="password"
                          {...register('password')}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                      <FormGroup>
                        <label>Confirmar contraseña:</label>
                        <input
                          className="form-control"
                          placeholder="Confirma contraseña"
                          type="password"
                          {...register('confirmPassword')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <label>Telefono:</label>
                      <input
                        className="form-control"
                        defaultValue={loggedUser.phoneNumber}
                        {...register('phoneNumber')}
                        type="text"
                        id="phoneNumber"
                      />
                    </Col>
                  </Row>
                  <Button className="btn-fill" color="blue" type="submit">
                    Editar
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default UserProfile;

/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
