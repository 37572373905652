import {
  FormGroup,
  Label,
  Button,
  Card,
  CardImg,
  CardBody,
  CardImgOverlay,
  CardTitle,
  CardText,
  Row,
  Col,
} from 'reactstrap';
import React, { useState, useEffect } from 'react';
import Alerts from '../components/Modal/Alert';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, Navigate } from 'react-router-dom';
import { useLoginStore } from '../stores/loginStore';
import logo from '../assets/isologo.svg';
import logoVerde from '../assets/Logotipo_verde.png';
import backgroundimg from '../assets/img/background.webp';
const LoginView = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const { loginHandler } = useAuth();
  const { loggedUser } = useLoginStore();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submitLogin = (data, e) => {
    loginHandler(data).then((isLogged) => {
      if (isLogged) {
        setShowError(false);
        navigate('/');
      } else {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 3000);
      }
    });
    e.preventDefault();
  };
  if (loggedUser) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <div className=" m-0 p-0">
      <Card className="mb-0 h-100">
        <Row align="left" className="w-100">
          <Col xs="12" lg="8" className="d-none d-md-block">
            <CardImg
              src={backgroundimg}
              alt="Imagen de fondo Agiliza"
              className="h-100vh"
            />

            <div className="container">
              <CardImgOverlay className="card-body-bg-tx w-100 d-flex align-items-center justify-content-between">
                <div className="titles">
                  <CardTitle
                    className="h2"
                    style={{
                      fontSize: '2.5rem',
                      fontFamily: 'futura, sans-serif',
                      fontWeight: 'bold',
                      color: '#3ed2c6',
                      textAlign: 'center',
                    }}
                  >
                    Impulsa el éxito
                  </CardTitle>
                  <CardText
                    className="h3"
                    style={{
                      fontSize: '2.3rem',
                      fontFamily: 'futura, sans-serif',
                      fontWeight: 'bold',
                      color: '#3ed2c6',
                      textAlign: 'center',
                    }}
                  >
                    Transforma tu negocio
                  </CardText>
                </div>
                <img
                  src={logo}
                  alt="Logo agiliza"
                  style={{
                    width: '15rem',
                    height: '15rem',
                    position: 'relative',
                    top: '1rem',
                    left: '6rem',
                  }}
                />
              </CardImgOverlay>
            </div>
          </Col>
          <Col xs="12" lg="4">
            <CardBody className="d-flex align-items-center justify-content-center w-100 h-100vh">
              <div>
                <img
                  src={logoVerde}
                  alt="Logo agiliza"
                  style={{
                    width: '150px',
                    height: 'auto',
                    position: 'absolute',
                    top: '20%',
                    left: '35%',
                  }}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <form className="login" onSubmit={handleSubmit(submitLogin)}>
                  <FormGroup>
                    <Label for="exampleEmail">Usuario</Label>
                    <input
                      className={`form-control ${errors.userNameOrEmail && 'is-invalid'}`}
                      type="text"
                      name="userNameOrEmail"
                      id="userNameOrEmail"
                      placeholder="Ingresa tu correo o nombre de usuario"
                      autoComplete="on"
                      {...register('userNameOrEmail', {
                        required: 'El campo usuario/email es requerido',
                      })}
                    />
                    <span>{errors.userNameOrEmail?.message}</span>
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">Contraseña</Label>
                    <input
                      className={`form-control ${errors.password && 'is-invalid'}`}
                      type={passwordVisible ? 'text' : 'password'}
                      name="password"
                      id="password"
                      placeholder="Contraseña"
                      autoComplete="off"
                      {...register('password', {
                        required: 'El campo contraseña es requerido',
                      })}
                    />
                    <span>{errors.password?.message}</span>
                    <FormGroup check>
                      <Label check htmlFor="showPassword">
                        <input
                          id="showPassword"
                          type="checkbox"
                          onChange={(e) => {
                            setPasswordVisible(e.target.checked);
                          }}
                        />
                        Mostrar contraseña
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup check>
                    <Label check htmlFor="remember">
                      <input
                        id="remember"
                        type="checkbox"
                        {...register('remember')}
                      />
                      Recuerdame
                      <span className="form-check-sign">
                        <span className="check"></span>
                      </span>
                    </Label>
                  </FormGroup>

                  <Button color="blue" type="submit">
                    Entrar
                  </Button>
                </form>
              </div>
            </CardBody>
            {showError && (
              <Alerts data="Credenciales incorrectas" show={showError} />
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default LoginView;
