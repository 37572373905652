import { Navigate } from 'react-router';
import { useLoginStore } from '../stores/loginStore';

function LoginRequired({ children }) {
  const { loggedUser } = useLoginStore();
  if (loggedUser === undefined) {
    return (
      <>
        <Navigate to="/login" replace={true}
        />
      </>
    );
  }
  return { ...children };
}

export default LoginRequired;
