import { useEffect, useCallback, useState } from 'react';
import {
  login,
  logout,
  getCurrentUser,
  getPowerBiSrc,
} from '../services/apiFetches';
import { useLoginStore } from '../stores/loginStore';

export function useAuth() {
  const [isFetch, setIsFetch] = useState(false);
  const {
    setLoggedUser,
    removeLoggedUser,
    setLoggedUserLinks,
    setFetched,
    loggedUser,
  } = useLoginStore();

  const loginHandler = useCallback(
    async ({ userNameOrEmail, password, remember }) => {
      const response = await login({ userNameOrEmail, password, remember });
      if (response === undefined) {
        return false;
      }
      const user = await getCurrentUser();
      const links = await getPowerBiSrc({ permissions: user.permissions });
      setLoggedUser(user);
      setLoggedUserLinks(links);
      setIsFetch(true);
      return true;
    },
    [setLoggedUser, setLoggedUserLinks],
  );

  const logoutHandler = useCallback(async () => {
    const loggedOut = await logout();
    removeLoggedUser();
    setIsFetch(false);
    return loggedOut;
  }, [removeLoggedUser]);

  useEffect(() => {
    if (loggedUser !== undefined) return;
    if (loggedUser === undefined && isFetch) return;
    getCurrentUser().then((data) => {
      if (data === undefined) {
        setIsFetch(true);
        return;
      }
      getPowerBiSrc({ permissions: data.permissions })
        .then((links) => {
          setLoggedUser(data);
          setLoggedUserLinks(links);
          console.log(data, links);
        })
        .finally(() => {
          setIsFetch(true);
        });
    });
  }, [setLoggedUser, setLoggedUserLinks, setFetched, loggedUser, isFetch]);

  return { loginHandler, logoutHandler, isFetch };
}
