import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Form,
} from 'reactstrap';

import { useForm } from 'react-hook-form';
import Alerts from 'components/Modal/Alert';
import { createUser } from 'services/apiFetches';

function UserAdd(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);

  // @function { toggle } - Encargada de ocultar el modal.

  const toggle = () => setModal(!modal);

  // @function { onSubmit } - Enfunción que ejecuta el envio de datos del formulario.
  // @params { object } data - Los datos del formulario.
  const keyPermissions = {
    Oldelval: 'tim-icons icon-chart-pie-36 ',
    Siemens: 'tim-icons icon-bag-16 ',
    BTU: 'tim-icons icon-bank ',
    admin: 'tim-icons icon-single-02 ',
    YPF: 'tim-icons icon-components ',
  };
  const onSubmit = async (data) => {
    data.permissions = data.permissions.filter((permission) => permission);
    const result = await createUser({ userInfo: data });
    if (result.error) {
      setError(result.error);
    } else {
      props.fetchUsers();
      toggle();
    }
  };

  return (
    <section>
      <Button color="blue" onClick={toggle}>
        Agregar usuario
      </Button>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader className="h3" toggle={toggle}>
          Agregar Usuario
        </ModalHeader>

        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="pr-md-1" md="6">
                <FormGroup>
                  <label>Nombre:</label>
                  <input
                    placeholder="Humberto"
                    type="text"
                    className="form-control"
                    {...register('name', {
                      required: 'Este campo es obligatorio',
                      maxLength: {
                        value: 20,
                        message: 'Maximo 20 caracteres',
                      },
                    })}
                  />
                  {errors.name && (
                    <span className="alert_red"> {errors.name.message} </span>
                  )}
                </FormGroup>
              </Col>

              <Col className="pl-md-1" md="6">
                <FormGroup>
                  <label>Apellido:</label>
                  <input
                    placeholder="Rodriguez"
                    type="text"
                    className="form-control"
                    {...register('lastName', {
                      required: 'Este campo es obligatorio',
                      maxLength: {
                        value: 20,
                        message: 'Maximo 20 caracteres',
                      },
                    })}
                  />
                  {errors.lastName && (
                    <span className="alert_red">{errors.lastName.message}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Correo Electronico:</label>
                  <input
                    placeholder="mike@email.com"
                    type="email"
                    className="form-control"
                    {...register('email', {
                      required: 'Este campo es obligatorio',
                    })}
                  />

                  {errors.email && (
                    <span className="alert_red"> {errors.email.message} </span>
                  )}
                </FormGroup>
              </Col>

              <Col className="pr-md-1 pl-md-1" md="4">
                <FormGroup>
                  <label>Contraseña:</label>
                  <input
                    placeholder="***********"
                    type="password"
                    className="form-control"
                    {...register('password', {
                      required: 'Este campo es obligatorio',
                    })}
                  />

                  {errors.password && (
                    <span className="alert_red">
                      {errors.password.message}{' '}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col className="pl-md-1" md="4">
                <FormGroup>
                  <label>Confirmación de contraseña:</label>
                  <input
                    placeholder="***********"
                    type="password"
                    className="form-control"
                    {...register('confirmPassword', {
                      required: 'Este campo es obligatorio',
                    })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="pr-md-1" md="4">
                <FormGroup>
                  <label>Usuario:</label>
                  <input
                    placeholder="HumberRodri"
                    className="form-control"
                    {...register('userName', {
                      required: 'Este campo es obligatorio',
                    })}
                  />
                  {errors.userName && (
                    <span className="alert_red">
                      {errors.userName.message}{' '}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col className="pr-md-1 pl-md-1" md="4">
                <FormGroup>
                  <label>Telefono:</label>
                  <input
                    placeholder="(+54) 9 11 0000 0000"
                    type="tel"
                    className="form-control"
                    {...register('phoneNumber', {
                      required: 'Este campo no debe estar vacio',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Este campo espera solo numeros',
                      },
                      minLength: {
                        value: 8,
                        message: 'error message',
                      },
                    })}
                  />

                  {errors.phoneNumber && (
                    <span className="alert_red">
                      {errors.phoneNumber.message}{' '}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col className="pl-md-1" md="4">
                <fieldset>
                  <label>Permisos:</label>
                  <FormGroup className="d-flex justify-content-between mb-0">
                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['Siemens']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Siemens"
                      />
                      <input
                        type="checkbox"
                        className="form-control"
                        value={'Siemens'}
                        {...register('permissions.0', { value: 'Siemens' })}
                      />
                    </FormGroup>
                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['Oldelval']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Oldelval"
                      />

                      <input
                        type="checkbox"
                        value={'Oldelval'}
                        className="form-control"
                        {...register('permissions.1', { value: 'Oldelval' })}
                      />
                    </FormGroup>

                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['BTU']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="BTU"
                      />
                      <input
                        type="checkbox"
                        value={'BTU'}
                        className="form-control"
                        {...register('permissions.2', { value: 'BTU' })}
                      />
                    </FormGroup>

                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['YPF']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="YPF"
                      />
                      <input
                        type="checkbox"
                        className="form-control"
                        value={'YPF'}
                        {...register('permissions.3', { value: 'YPF' })}
                      />
                    </FormGroup>

                    <FormGroup className="permisos">
                      <i
                        className={keyPermissions['admin']}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="admin"
                      />
                      <input
                        type="checkbox"
                        className="form-control"
                        value={'admin'}
                        {...register('permissions.4', { value: 'admin' })}
                      />
                    </FormGroup>
                  </FormGroup>
                </fieldset>
              </Col>
            </Row>

            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={toggle}
                className="mr-5 mt-3 mb-1"
              >
                Cancelar
              </Button>

              <Button color="blue" type="submit" className="ml-5 mt-3 mb-1">
                Guardar
              </Button>
            </ModalFooter>
          </Form>
          {error && <Alerts data={error} />}
        </ModalBody>
      </Modal>
    </section>
  );
}

export default UserAdd;
