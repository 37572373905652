import { create } from 'zustand';

export const useLoginStore = create((set) => ({
  loggedUser: undefined,
  loggedUserLinks: undefined,
  activeLink: undefined,
  setLoggedUser: ({
    userName,
    name,
    lastName,
    email,
    password,
    phoneNumber,
    permissions,
    id,
  }) =>
    set({
      loggedUser: {
        userName,
        name,
        lastName,
        email,
        password,
        phoneNumber,
        permissions,
        id,
      },
    }),
  removeLoggedUser: () => {
    set({
      loggedUser: undefined,
      loggedUserLinks: undefined,
      activeLink: undefined,
    });
  },
  setLoggedUserLinks: (links) => set({ loggedUserLinks: links }),
  setActiveLink: (newLink) => set({ activeLink: newLink }),
}));
